import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
//import App from './App/App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.bundle.js';
import { Provider } from 'react-redux';
import { RouterProvider } from "react-router-dom";
import router from './router';
import store from './App/store';
//import { ParallaxProvider } from 'react-scroll-parallax';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider 
    store={store}
    >
      
    <RouterProvider 
    router={router}
    
    />
    
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
